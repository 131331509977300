import {Component, Input,  OnInit, ViewChild} from '@angular/core';
import {finalize} from "rxjs/operators";
import {School} from "../../model/rest/casa-apiv2.model";
import {SnipcartItem} from "../../services/student-snipcart.service";
import {environment} from "../../../environments/environment";
import {Utils} from "../../services/utils/utils";
import {CasaRestApiV2} from "../../services/rest/casa-rest.service";
import {orderBuilder, SimpleOrder} from "../../model/transactional.v2";
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-buy-credits-cart',
  templateUrl: './buy-credits-cart.component.html',
  styleUrls: ['./buy-credits-cart.component.css']
})
export class BuyCreditsCartComponent implements OnInit {
  private _school: School;
  private initialized = false;

  @ViewChild("creditsCart", {static: true})
  modal: ModalComponent;
  currentCart: {[productId: string]: number} = {};
  loading = false;

  @Input()
  set school(school: School) {
    this._school = school;
    this.init();
  }

  products = [
    new SnipcartItem(null,
      "CreditEn.r3.10PCK",
      "Callan English 3rd Edition eBook pack",
      "10 credits",
      90.65, null, null,
      null),
    new SnipcartItem(null,
      "CreditSP.10PCK",
      "Callan Español eBook pack",
      "10 credits",
      99.90, null, null,
      null),
    new SnipcartItem(null,
      "CreditCfk.10PCK",
      "Callan for Kids eBook pack",
      "10 credits (full Lesson content, without activities)",
      127.75, null, null,
      null),
    new SnipcartItem(null,
      "CreditBs.1PCK",
      "Callan for Business eBook",
      "1 credit (sold individually)",
      15.15, null, null,
      null)
  ];

  constructor(
    private casaRest: CasaRestApiV2
  ) { }

  ngOnInit() {
  }

  private init() {
    if (!this._school) return;
    if (!this.initialized) {
      this.reloadCart();
      this.initialized = true;
    }
  }

  show() {
    this.modal.show();
    this.reloadCart();
  }

  getCurrency() {
    return "£";
  }

  add(product: SnipcartItem, value: number) {
    let quantity = this.currentCart[product.id];
    if (quantity == null) {
      quantity = 0;
    }
    quantity += value;
    if (quantity < 0) return;
    this.currentCart[product.id] = quantity;
  }

  getQuantity(product: SnipcartItem) {
    const quantity = this.currentCart[product.id];
    if (!quantity) return 0;
    return quantity;
  }

  private reloadCart() {
    this.currentCart = {};
  }

  public checkout() {
    this.loading = true;

    const order = this.mapToOrder();

    this.casaRest.createSchoolOrderCheckout(
      this._school.id,
      `${environment.serverBase}/school/${this._school.id}/payment/confirm`,
      `${environment.serverBase}/school/${this._school.id}/payment/cancel`,
      order
    ).pipe(
      finalize( () => {
        this.loading = false
        this.hide();
      })
    ).subscribe(
      checkoutUrl => {
        window.location.href = checkoutUrl
      }
    )

  }

  private hide() {
    this.modal.hide();
  }

  getProductPrice(product: SnipcartItem) {

    return `${this.getCurrency()}${Utils.padToCurrency(product.price)}`;
  }

  private mapToOrder(): SimpleOrder {
    const builder = orderBuilder();
    this.products.map( product => ({
      product,
      quantity : this.currentCart[product.id]
    }))
      .filter( row => row.quantity)
      .forEach( row => {
        builder.newEntry(row.product.id, row.quantity)
          .withPrice("regular", row.product.price, "gbp")
          .allowQuantityAdjust().finish()
      })
    return builder.build();
  }
}
