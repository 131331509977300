import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  ApiLearningUnitTeacher,
  ApiLessonInstance,
  ApiPersonalProfile,
  ApiTeacherProfile,
  EntityRelatedRow
} from 'src/app/model/rest/rest-model';
import { ParamTypes, ReportRequestParam, ReportResponse, ReportSpecification } from 'src/app/model/report';
import { UserRole } from '../col2/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsRestService {

  constructor( private http: HttpClient) {}

  prepareSchoolUrl( schoolId: number) {
    return environment.apiEndpoint + '/reports/school/' + schoolId;
  }

  private static prepareReportBaseQueryParams(dateFrom: Date, dateTo: Date, productCode: string) {
    let params = new HttpParams();
    if (dateFrom) {
      params = params.append('dateFrom', dateFrom.toISOString());
    }
    if (dateTo) {
      params = params.append('dateTo', dateTo.toISOString());
    }
    if (productCode) {
      params = params.append('productCode', productCode);
    }
    return params;
  }

  listSchoolTeacherLessonsTypesReport( schoolId: number, dateFrom: Date, dateTo: Date, productCode: string)
  : Observable<EntityRelatedRow<ApiTeacherProfile>[]> {
    const url = this.prepareSchoolUrl(schoolId) + '/teacher-lesson-types';
    const params = ReportsRestService.prepareReportBaseQueryParams(dateFrom, dateTo, productCode);

    return this.http.get<EntityRelatedRow<ApiTeacherProfile>[]>(url, {params: params});
  }

  listLessonDetails(schoolId: number, dateFrom: Date, dateTo: Date, productCode: string,
    lessonType: string[], lessonStatus: string[], flags: string[])
    : Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>[]> {
      const url = this.prepareSchoolUrl(schoolId) + '/lessons';

      let params = ReportsRestService.prepareReportBaseQueryParams(dateFrom, dateTo, productCode);
      for (const t of lessonType) {
        params = params.append('lessonType', t);
      }
      for (const s of lessonStatus) {
        params = params.append('lessonStatus', s);
      }
      if (flags) {
        for (const f of flags) {
          params = params.append('withFlags', f);
        }
      }
      return this.http.get<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>[]>(url, {params: params});
    }

    getReport(schoolId: number, report: ReportSpecification, parameters: ReportRequestParam[], role: UserRole) {
      const url = `${report.endpointApiBase}${report.endpointApiBase === environment.videoApiBase ? '/sec' : ''}${role === UserRole.Admin ? '' : `/school/${schoolId}`}/reports${report.endpoint}`
      let params = new HttpParams();

      parameters.map(parameter => {
        if(!parameter.value) {}
        else if(parameter.type in [ParamTypes.date, ParamTypes.dateRange, ParamTypes.dateTime, ParamTypes.dateTimeRange]){
          let date = new Date(parameter.value)
          params = params.append(parameter.paramName, date.toISOString())
        }
        else if(parameter.type === ParamTypes.string) {
          params = params.append(parameter.paramName, parameter.value.trim())
        } else {
          params = params.append(parameter.paramName, parameter.value)
        }
      })
      
      return this.http.get<ReportResponse>(url, {params: params} )
    }

}
