import { SortingRule } from '../services/utils/manager-utils';

export enum NoteTypes {
  general = 'General',
  meeting = 'Meeting',
  call = 'Call',
  email = 'Email',
}

export class NotesSortingRule extends SortingRule {
  constructor(public name: string, sorting: string[]) {
    super(sorting);
  }
  static DateAsc = new NotesSortingRule('DateAsc', ['createDate,ASC']);
  static DateDesc = new NotesSortingRule('DateDesc', ['createDate,DESC']);
  static AuthorAsc = new NotesSortingRule('AuthorAsc', ['author,ASC']);
  static AuthorDesc = new NotesSortingRule('AuthorDesc', ['author,DESC']);

  static getSortingRule(rule: string) {
    switch (rule) {
      case 'DateAsc':
        return NotesSortingRule.DateAsc;
      case 'DateDesc':
        return NotesSortingRule.DateDesc;
      case 'AuthorAsc':
        return NotesSortingRule.AuthorAsc;
      case 'AuthorDesc':
        return NotesSortingRule.AuthorDesc;
      default:
        break;
    }
  }
  static getNoteTypes() {
    return [
      NoteTypes.general,
      NoteTypes.meeting,
      NoteTypes.call,
      NoteTypes.email,
    ];
  }
}

export enum SearchTypeName {
  ALL_TYPES = 'All',
  SCHOOL_NAME = 'School name',
  SCHOOL_ID = 'School ID',
  SCHOOL_OLD_ID = 'Old school ID',
  COUNTRY = 'Country',
  STATUS = 'Status',
  EXPIRY_DATE = 'Expiry date',
}
export class SearchType {
  static SCHOOL_NAME = 'SCHOOL_NAME';
  static SCHOOL_ID = 'SCHOOL_ID';
  static SCHOOL_OLD_ID = 'SCHOOL_OLD_ID';
  static COUNTRY = 'COUNTRY';
  static STATUS = 'STATUS';
  static EXPIRY_DATE = 'EXPIRY_DATE';
  static ALL = [
    SearchType.SCHOOL_NAME,
    SearchType.SCHOOL_ID,
    SearchType.SCHOOL_OLD_ID,
    SearchType.COUNTRY,
    SearchType.STATUS,
    SearchType.EXPIRY_DATE,
  ];
}

export class SchoolsSortingRule extends SortingRule {
  constructor(sorting: string[]) {
    super(sorting);
  }
  static Default = new SchoolsSortingRule(['', '']);
  static SchoolNameAsc = new SchoolsSortingRule(['name','ASC']);
  static SchoolNameDesc = new SchoolsSortingRule(['name','DESC']);
  static IdAsc = new SchoolsSortingRule(['id','ASC']);
  static IdDesc = new SchoolsSortingRule(['id','DESC']);
  static StatusAsc = new SchoolsSortingRule(['status','ASC']);
  static StatusDesc = new SchoolsSortingRule(['status','DESC']);
  static CountedActiveStudentsAsc = new SchoolsSortingRule(['countedActiveStudents','ASC']);
  static CountedActiveStudentsDesc = new SchoolsSortingRule(['countedActiveStudents','DESC']);
  static ActiveTeachersAsc = new SchoolsSortingRule(['activeTeachers','ASC']);
  static ActiveTeachersDesc = new SchoolsSortingRule(['activeTeachers','DESC']);
  static CountryAsc = new SchoolsSortingRule(['address.country.name','ASC']);
  static CountryDesc = new SchoolsSortingRule(['address.country.name','DESC']);
  static ExpiryAsc = new SchoolsSortingRule(['expDate','ASC']);
  static ExpiryDesc = new SchoolsSortingRule(['expDate','DESC']);
  static values = [
    SchoolsSortingRule.Default,
    SchoolsSortingRule.SchoolNameAsc,
    SchoolsSortingRule.SchoolNameDesc,
    SchoolsSortingRule.IdAsc,
    SchoolsSortingRule.IdDesc,
    SchoolsSortingRule.StatusAsc,
    SchoolsSortingRule.StatusDesc,
    SchoolsSortingRule.CountedActiveStudentsAsc,
    SchoolsSortingRule.CountedActiveStudentsDesc,    
    SchoolsSortingRule.ActiveTeachersAsc,
    SchoolsSortingRule.ActiveTeachersDesc,
    SchoolsSortingRule.CountryAsc,
    SchoolsSortingRule.CountryDesc,
    SchoolsSortingRule.ExpiryAsc,
    SchoolsSortingRule.ExpiryDesc,
  ];
}
