import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { ExcelReportAddonTypes, ExcelReportSpecification, ExcelReportsSpecification } from '../model/report';

@Injectable({
  providedIn: 'root'
})
export class ExportExcelService {

  excelReportsSpecification: Map<string, ExcelReportSpecification>
  specification: ExcelReportSpecification

  constructor() {
    this.excelReportsSpecification = ExcelReportsSpecification
  }

  exportToExcel(data: any, fileName: string, reportKey: string){
    this.specification = this.excelReportsSpecification.get(reportKey) ? this.excelReportsSpecification.get(reportKey) : this.excelReportsSpecification.get("default")

    const customText = this.getAddons()
    const colHeaders = Object.keys(data[0])
    const dataWithHeaders = [colHeaders, ...data.map(item => Object.values(item))];

    const worksheet = XLSX.utils.aoa_to_sheet(customText);
    const worksheetToCalculateWidths = XLSX.utils.aoa_to_sheet(dataWithHeaders);
    
    XLSX.utils.sheet_add_aoa(worksheet, dataWithHeaders, { origin: this.specification.startOfTable })

    const columnWidths = this.calculateColumnWidths(worksheetToCalculateWidths);
    worksheet['!cols'] = columnWidths;
    worksheet['!autofilter'] = { ref: this.getTableRange(dataWithHeaders) };

    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFileXLSX(workbook, `${fileName}.xlsx`)
  }

  private calculateColumnWidths(worksheet: XLSX.WorkSheet): Array<{ wch: number }> {
    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    const colWidths: number[] = [];

    // Calculate the maximum width for each column
    jsonData.forEach((row: (string | number | undefined)[]) => {
      row.forEach((cell: string | null | undefined, colIndex: number) => {
        const cellLength = (cell ? cell.toString().length : 0);
        colWidths[colIndex] = Math.max(colWidths[colIndex] || 0, cellLength);
      });
    });

    // Convert lengths to column width objects
    return colWidths.map(width => ({ wch: width + 2 })); //padding
  }

  private getTableRange(data: any[][]): string {
    const startCol = this.specification.startOfTable.charAt(0)
    const startRow = this.specification.startOfTable.charAt(1)

    const numOfCols = data[0].length
    let colFirstLetterCode: number, colSecondLetterCode: number, endCol: string

    // range for capital letters is 64-90, if last col has more than 90 it's two (or more) -letter code
    if(numOfCols + 64 > 90) {
      colFirstLetterCode = Math.trunc(numOfCols / 26)  //every 26 letters the first letter is different
      colSecondLetterCode = numOfCols % 26 // the rest is code for second letter
      endCol = String.fromCharCode(64 + colFirstLetterCode, 64 + colSecondLetterCode);
    }
    else {
      endCol = String.fromCharCode(64 + numOfCols);
    }

    const endRow = data.length + Number.parseInt(startRow) - 1 //table rows are shifted by information at the top 

    return `${startCol}${startRow}:${endCol}${endRow}`;
  }

  private getAddons(){
    let customData = []

    for (const [key, value] of this.specification.excelSpecs.entries()){
      if(value.type === ExcelReportAddonTypes.generatedOn){
        customData.push([value.text + new Date().toLocaleString()])
      }
      else {
        customData.push([value.text])
      }
    }
    return customData
  }
}
