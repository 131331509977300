import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SchoolsSortingRule, SearchType, SearchTypeName } from 'src/app/model/admin';
import { Page, Pageable } from 'src/app/model/rest/base';
import {
  School,
  SchoolStatus,
  SchoolStatusEnum,
  SchoolWithActiveTeachers,
} from 'src/app/model/rest/casa-apiv2.model';
import { FunctionalAccessService } from 'src/app/services/functional-access.service';
import { AdminRestService } from 'src/app/services/rest/admin-rest.service';
import { LocalState } from 'src/app/services/utils/manager-utils';

class SearchForm {
  searchType: SearchType = SearchType.SCHOOL_NAME;
  searchValue: string;
  schoolStatus: SchoolStatus;
  dateAfter: Date;
  dateBefore: Date;
}
@Component({
  selector: 'app-school-list-page',
  templateUrl: './school-list-page.component.html',
  styleUrls: ['./school-list-page.component.scss'],
})
export class SchoolListPageComponent implements OnInit {
  schoolsPage: Page<SchoolWithActiveTeachers>;
  searchForm: SearchForm = new SearchForm();
  sortingRules = SchoolsSortingRule.values;
  currentState: LocalState<SchoolsSortingRule>;
  pageable = new Pageable(0, 10, []);
  sortDirection: 'ASC' | 'DESC' | null = 'ASC'
  sortColumn: string = SchoolsSortingRule.IdAsc.sorting[0]
  loading: boolean = false

  sortColumns = {
    schoolName: SchoolsSortingRule.SchoolNameAsc.sorting[0],
    schoolId: SchoolsSortingRule.IdAsc.sorting[0],
    country: SchoolsSortingRule.CountryAsc.sorting[0],
    status: SchoolsSortingRule.StatusAsc.sorting[0],
    expiry: SchoolsSortingRule.ExpiryAsc.sorting[0],
    activeStudents: SchoolsSortingRule.CountedActiveStudentsAsc.sorting[0],
    activeTeachers: SchoolsSortingRule.ActiveTeachersAsc.sorting[0]
  }  

  constructor(
    private adminRest: AdminRestService,
    private faService: FunctionalAccessService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadSchools();
    this.currentState = new LocalState(
      '',
      SchoolsSortingRule.Default,
      this.pageable
    );
  }

  loadSchools() {
    this.loading = true
    this.adminRest.listSchools(this.pageable).subscribe((schoolsPage) => {
      this.schoolsPage = schoolsPage;
      this.loading = false
    });
  }

  getAllSearchTypes() {
    return SearchType.ALL;
  }

  getSearchTypeName(key: string) {
    return SearchTypeName[key];
  }

  resetDates() {
    this.searchForm.dateAfter = null;
    this.searchForm.dateBefore = null;
  }

  isSearchByExpDate() {
    return this.searchForm.searchType === SearchType.EXPIRY_DATE;
  }
  isSearchByStatus() {
    return this.searchForm.searchType === SearchType.STATUS;
  }

  getSchoolStatusList() {
    return SchoolStatus.ALL;
  }

  getSchoolStatusName(key: string) {
    return SchoolStatusEnum[key];
  }

  resetSearch() {
    this.searchForm = new SearchForm();
    this.updateSearch();
  }

  sortTable(colName: string) {
    this.pageable.page = 0;
    if(this.currentState.sortingRule.sorting[0]?.split(',')[0] === colName){ // change direction
      this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC'
    } else {
      this.sortColumn = colName
      this.sortDirection = 'ASC'
    }

    this.currentState.sortingRule = this.sortingRules.find(sortingRule => {
      if(sortingRule.sorting[0] == colName && sortingRule.sorting[1] == this.sortDirection) return true 
    }
    )
    this.pageable.sort[0] = this.currentState.sortingRule.sorting.join()
    this.loadSchools();
  }

  updateSearch() {
    const searchTerm = this.searchForm.searchValue?.toLowerCase();
    let observable = new Observable<Page<SchoolWithActiveTeachers>>()
    if (!searchTerm && !this.isSearchByExpDate() && !this.isSearchByStatus()) {
      this.adminRest.listSchools(this.pageable).subscribe(schoolsPage =>{
        this.schoolsPage = schoolsPage
        this.loading = false
      })
      return;
    }

    switch (this.searchForm.searchType) {
      case SearchType.SCHOOL_NAME:
        observable = this.adminRest.listSchools(this.pageable, searchTerm)
        break;
      case SearchType.SCHOOL_ID:
        observable = this.adminRest.listSchools(this.pageable, null, searchTerm)
        break;
      case SearchType.SCHOOL_OLD_ID:
        observable = this.adminRest.listSchools(this.pageable, null, null, searchTerm)
        break;
      case SearchType.COUNTRY:
        observable = this.adminRest.listSchools(this.pageable, null, null, null, searchTerm)
        break;
      case SearchType.STATUS:
        observable = this.adminRest.listSchools(this.pageable, null, null, null, null, this.searchForm.schoolStatus)
        break;
      case SearchType.EXPIRY_DATE:
        observable = this.adminRest.listSchools(this.pageable, null, null, null, null, null, this.searchForm.dateAfter, this.searchForm.dateBefore)
        break;
      default:
        break;
    }

    observable.subscribe(schoolsPage => {
      this.schoolsPage = schoolsPage
      this.loading = false
    })
  }

  switchPage($event) {
    this.pageable = $event
    this.updateSearch()
  }

  navigateBasingOnRole(school: School) {
    this.faService.isSuperUser().subscribe(res => {
      if(res)
        this.router.navigate([`/admin/school/${school.id }`])
      else
        this.router.navigate([`/school/${school.id}/dashboard`])
    })
  }
}
