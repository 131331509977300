<!-- <app-dashboard-school-info-block [school]="school"></app-dashboard-school-info-block> -->
    <div class="row g-4 g-md-5">
      <div class="col-12 col-lg-6">
        <app-students-summary-block [school]="school"></app-students-summary-block>
      </div>
      <div class="col-12 col-lg-6">
        <app-teachers-summary-block [school]="school"></app-teachers-summary-block>
      </div>
      <div class="col-12 col-lg-6">
        <app-students-payment-summary-block [school]="school"></app-students-payment-summary-block>
      </div>
      <div class="col-12 col-lg-6">
        <app-credits-summary-block [school]="school"></app-credits-summary-block>
      </div>
      <div class="col-12">
        <app-managers-block [school]="school"></app-managers-block>
      </div>
      <div class="col-12">
        <app-invitations-block [school]="school"></app-invitations-block>
      </div>
    </div>
